import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Scrollspy from "react-scrollspy";

import { CBTNLogoMono } from "../components/Illustrations/Logo";
import { Image } from "../components/Image";
import { Button } from "../components/Button";
import { Footer } from "../components/Footer";

import Leadership from "../images/annualreport/leadership.png";
import LeadershipSig from "../images/annualreport/leadershipsig.png";
import BrianRoodPic from "../images/annualreport/brianrood.png";
import CBTTC from "../images/annualreport/cbttc.png";
import Growth from "../images/annualreport/growth.png";
import Collage1 from "../images/annualreport/collage1.svg";
import Collage2 from "../images/annualreport/collage2.svg";
import Monje from "../images/annualreport/monje.png";
import ExecCouncil from "../images/annualreport/execcouncil.png";
import DipgSigns from "../images/annualreport/dipg_signs.png";
import JaceWard from "../images/annualreport/jace_ward.png";
import MarkCowley from "../images/annualreport/mark_cowley.png";
import Budget from "../images/annualreport/budget.png";
import PatientPartnershipsHeader from "../images/annualreport/patientpartnershipsheader.png";
import ScientificPartnershipsHeader from "../images/annualreport/scientificpartnershipsheader.png";
import AdvancingMissionHeader from "../images/annualreport/advancingmissionheader.png";

const Content = ({ children, className = "" }) => (
  <div
    className={`px-4 prose prose-annual lg:prose-lg m-auto max-w-6xl ${className}`}
  >
    {children}
  </div>
);

const Columns = ({ children }) => (
  <div className="lg:grid lg:grid-cols-2 lg:gap-12">{children}</div>
);
const Curve = () => (
  <Image
    className="absolute top-auto"
    style={{
      bottom: "-1px",
      width: "110%",
    }}
    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSIyMTYiIHZpZXdCb3g9IjAgMCAxNDQwIDIxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNzIwIiBjeT0iMTMzMiIgcj0iMTMyOCIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=="
  />
);

const Header = ({ children, className = "" }) => (
  <div
    className={`relative flex justify-center item-center content-center w-screen mb-12 pt-12 lg:py-24 ${className}`}
  >
    {children}
  </div>
);

const HeaderContent = ({ children, className = "" }) => (
  <div
    className={`relative flex flex-col justify-center items-center content-center max-w-7xl ${className}`}
  >
    {children}
  </div>
);

const HeaderTitle = ({ children }) => (
  <h2 className="text-white text-5xl mb-12 lg:text-7xl lg:mb-24 font-semibold">
    {children}
  </h2>
);

const HeaderImage = ({ caption, ...rest }) => (
  <figure
    style={{
      width: "clamp(45ch, 70%, 90ch)",
    }}
  >
    <Image
      className="rounded-lg text-3xl border-transparent"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
      }}
      {...rest}
    />
    {caption?.length > 0 && (
      <figcaption className="mt-6 px-4 py-2 flex text-sm text-gray-600 bg-gray-50">
        {caption}
      </figcaption>
    )}
  </figure>
);

const NavigationBar = ({ children }) => (
  <div
    className="flex justify-between items-center content-center m-auto h-24 bg-transparent absolute top-0 left-0 right-0 z-10"
    style={{
      width: "95%",
      maxWidth: "90rem",
    }}
  >
    {children}
  </div>
);

const AnnualReport = () => (
  <>
    <NavigationBar>
      <div className="flex justify-center items-center space-x-8">
        <Link to="/">
          <div className="w-60 text-navy-50">
            <CBTNLogoMono />
          </div>
        </Link>
        <Button as="a" href="/" variant="overColor">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
          Home
        </Button>
      </div>
    </NavigationBar>
    <Header className="bg-navy-800 h-screen">
      <HeaderContent className="h-full text-center mb-32">
        <h1 className="text-white text-9xl">
          Annual Report
          <br />
          2019-2020
          <br />
          <Button
            size="5"
            variant="overColor"
            as="a"
            href="https://cbtn.org/CBTN_Annual_Report_FY19-20.pdf"
          >
            Download Report.
          </Button>
        </h1>
      </HeaderContent>
    </Header>
    <main>
      <div className="mt-12" />
      <header className="bg-white sticky top-0 z-50">
        <Scrollspy
          componentTag="nav"
          className="flex space-x-8 m-auto max-w-6xl "
          aria-label="Report"
          items={[
            "mission",
            "leadership-perspective",
            "patient-partnerships",
            "scientific-partnerships",
            "advancing-mission",
          ]}
          currentClassName="bg-gray-200 text-gray-900 border-b-4"
        >
          <AnchorLink
            to="#mission"
            title="CBTN Mission"
            className="py-5 px-4 inline-flex items-center text-base font-medium text-gray-900 bg-gray-50 hover:bg-gray-100 hover:text-gray-900 border-brand-green"
          />
          <AnchorLink
            to="#leadership-perspective"
            title="Leadership Perspective"
            className="py-5 px-4 inline-flex items-center text-base font-medium text-gray-900 bg-gray-50 hover:bg-gray-100 hover:text-gray-900 border-brand-navy"
          />
          <AnchorLink
            to="#patient-partnerships"
            title="Patient Partnerships"
            className="py-5 px-4 inline-flex items-center text-base font-medium text-gray-900 bg-gray-50 hover:bg-gray-100 hover:text-gray-900 border-brand-purple"
          />
          <AnchorLink
            to="#scientific-partnerships"
            title="Scientific Partnerships"
            className="py-5 px-4 inline-flex items-center text-base font-medium text-gray-900 bg-gray-50 hover:bg-gray-100 hover:text-gray-900 border-brand-blue"
          />
          <AnchorLink
            to="#advancing-mission"
            title="Advancing Mission"
            className="py-5 px-4 inline-flex items-center text-base font-medium text-gray-900 bg-gray-50 hover:bg-gray-100 hover:text-gray-900 border-brand-orange"
          />
        </Scrollspy>
      </header>
      <div id="mission" className="pt-12">
        <Content>
          <Image
            width="1200"
            className="my-6"
            src="https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images/Asset_10_B4g4EKN.png"
          />
          <h2 className="text-center my-12">
            This report is dedicated to each and every child diagnosed with a
            brain tumor
          </h2>
          <Image
            width="1200"
            className="mt-12 mb-12"
            src="https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images/Asset_11_Nf8gPMv.png"
          />
        </Content>
        <div className="mt-24" />
        <div className="pb-16 bg-gradient-to-br from-brand-green to-green-600 lg:pb-0 lg:z-10 lg:relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="relative lg:-my-8 col-span-4">
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
              />
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                  <Image
                    className="object-cover lg:h-full lg:w-full"
                    width="400"
                    src="https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images/Asset_1_GCe5goe.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="mt-12 lg:m-0 lg:pl-8 col-span-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                <Content className="prose-overcolor text-green-50">
                  <h3>CBTN Vision</h3>
                  <p>
                    By accelerating the pace of translational research and the
                    discovery of new treatments, we are a global community with
                    the shared goal to save children and young adults from brain
                    tumors.
                  </p>
                  <h3>CBTN Mission</h3>
                  <p>
                    The Children’s Brain Tumor Network is dedicated to driving
                    innovative discovery, pioneering new treatments and
                    accelerating open science to improve health for all children
                    & young adults diagnosed with a brain tumor
                  </p>
                  <p className="text-sm">
                    Michelle Monje, MD, PhD, (on right) Principal Investigator
                    at Lucile Packard Children’s Hospital Stanford and member of
                    the CBTN’s Executive Board and Scientific Committee,
                    together with members of the Monje Laboratory team at
                    Stanford. Photo courtesy of Lucile Packard Children’s
                    Hospital Stanford.
                  </p>
                </Content>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-24" />

        <Content>
          <h2>Adapting to the COVID-19 Pandemic</h2>
          <h3>A NEW RESEARCH CHALLENGE</h3>
          <p>
            The global shutdown resulting from the COVID-19 pandemic has
            significantly impacted our CBTN research network. Laboratory
            research at numerous member sites was put on hold until new safety
            protocols could be implemented. Collection, analysis, and sharing of
            tumor tissue and other biospecimens also paused. Our partner
            foundations and patient families especially felt the effects of the
            pandemic on their fundraising and advocacy efforts, but found
            creative new ways to continue to support the CBTN’s research.
          </p>
          <p>
            As this public health crisis continues to impact communities around
            the world, the CBTN’s commitment remains undeterred. Thanks to the
            CBTN’s suite of cloud-based analysis platforms, data is accessible
            from anywhere on earth, allowing research projects to move forward.
            As new health and lab safety measures are implemented, biospecimen
            collection and analysis are steadily returning to their pre-pandemic
            levels. CBTN’s patient advocates and research supporters at every
            level are answering the call to address the unexpected funding
            challenges. Thanks to these generous financial gifts, the CBTN has
            continued the development of global collaborations, increased the
            number of samples in the biorepository, and shared vital data to
            enable advancements in innovative brain cancer research.
          </p>
          <p>
            The current pandemic is demonstrating the critical role that global
            collaboration is playing in the development of treatments for
            COVID-19. Working together has enabled the medical community to make
            discoveries faster than ever before. The CBTN was applying these
            lessons to the treatment of childhood brain tumors, even before the
            pandemic began. Collaboration and global partnership are woven in
            the very fabric of the CBTN.
          </p>
          <p>
            The CBTN is committed to treating childhood brain tumors with the
            same level of urgency and collaboration as was seen during the
            development of the COVID-19 vaccines. No single institution can
            collect enough specimens or data in isolation to make significant
            advances in brain tumor treatment, so it’s critical to leverage
            every available resource on behalf of affected children.
          </p>
          <p>
            The success of the CBTN is thanks to the committed teams of
            clinicians, researchers, patient families, and many others who won’t
            stop until every child is cured. The CBTN is extremely grateful for
            the dedication and partnership of each of these collaborators.
            Together, we’ve made tremendous strides in our understanding of
            brain tumor biology and will continue to work to accelerate
            treatments for each and every patient.
          </p>
        </Content>
        <div className="bg-gradient-to-br from-brand-purple to-purple-900 py-12 my-16">
          <Content className="prose-overcolor text-purple-50 m-auto max-w-4xl">
            <h2>Adapting to a new era of research</h2>
            <ul>
              <li>
                Globally, the COVID-19 pandemic touched nearly all facets of
                society, including clinical operations, research, and
                philanthropy across the CBTN.
              </li>
              <li>
                Throughout the pandemic, CTBN and its partners identified
                additional needs of the childhood brain tumor community.
              </li>
              <li>
                Each of the CBTN’s investigators, clinicians, and research
                coordinators adapted to new research challenges, and by the end
                of Fiscal Year 20, resumed our tissue collection and analysis
                efforts across the network.
              </li>

              <li>
                The CBTN’s cloud-based analysis platforms enabled the CBTN to
                increase and expand the amount of research data available to
                researchers across the globe.
              </li>
            </ul>
          </Content>
        </div>
      </div>
      <div id="leadership-perspective" className="pt-16">
        <Content>
          <h2>Leadership Perspective</h2>

          <Image height="256" className="my-6 rounded-lg" src={Leadership} />
          <Columns>
            <div>
              <p>
                The leadership team of the Children’s Brain Tumor Network (CBTN)
                could not reflect on this transformative year without first
                highlighting CBTN’s expanded mission and rebranding from our
                roots as the Children’s Brain Tumor Tissue Consortium (CBTTC).
              </p>
              <p>
                This evolution from CBTTC to CBTN ultimately represents our
                re-commitment to the same values that launched this partnership
                in 2011 — that innovation and collaboration, combined with a
                shared vision, will create a future where no child suffers or
                dies from a brain tumor. The growth we’ve experienced during an
                unprecedented 2020 has not come without challenges, but the
                CBTN’s unwaiver- ing commitement to think differently and
                innovate have defined a successful path forward for years to
                come.
              </p>
              <p>
                The events of this past year have revealed areas of additional
                need, including disparities in academic research and clinical
                care. Investigators from CBTN and the Pacific Pediatric
                Neuro-Oncology Consortium (PNOC), led by Drs. Cassie Kline and
                Angela Waanders, are working to address injustice and biases
                within the brain tumor community, to improve access to care for
                all patients.
              </p>
              <p>
                Creative solutions have led to significant triumphs by the CBTN
                during Fiscal Year (FY) 20. Nineteen research papers utilizing
                CBTN’s data and specimens were published in 13 scientific
                journals, including a landmark study published in partnership
                with the National Cancer Institute’s Clinical Proteomic Tumor
                Analysis Consortium (CPTAC). This collaborative effort produced
                a first-in-kind, large-scale analysis of the “proteogenomics” or
                the proteins of pediatric tumors.
              </p>
              <p>
                Among several new initiatives has been the establishment of
                joint CBTN & PNOC disease working groups, each consisting of
                experts across a number of pediatric brain tumor diagnoses and
                research emphases. The new working groups each include
                representation from the clinical, research and patient
                communities. New working groups are also being considered for
                many of the especially uncommon types of childhood brain tumors,
                with the goal to identify and develop new precision clinical
                trials and therapeutic options for children with brain tumors.
              </p>
            </div>
            <div>
              <p>
                The effects of the global pandemic have significantly impacted
                CBTN’s foundation and philanthropic partnerships. Through the
                dedication of CBTN’s foundation supporters and families, we’ve
                been able to sustain and even increase our research activities
                on behalf of all children with brain tumors. Our network of
                supporters has responded with new ways to provide the necessary
                funding for CBTN’s operations and expansion.
              </p>
              <p>
                Thanks to a generous $1.75 million investment from the Swifty
                Foundation early in FY 20, 13 new personnel have been added
                within CBTN’s Research Core and five research initiatives
                designed for CBTN have been launched to achieve a new level of
                operational excellence. This increase in experts on staff has
                thus far enabled the creation of diagnostic workflows critical
                to the support of clinical trials through our disease working
                groups.
              </p>
              <p>
                These infrastructure improvements have better positioned CBTN to
                secure sustainable federal funding from the National Institutes
                of Health (NIH). In FY20, CBTN submitted its first-ever
                pediatric brain tumor SPORE (Specialized Programs of Research
                Excellence) application to the National Institutes of Health,
                which represented a key opportunity to secure ongoing federal
                research funding. Results of Pediatric Brain Tumor SPORE
                submission are expected in FY21.
              </p>
              <p>
                Global opportunities, unprecedented challenges, and significant
                achievements have abounded this year, and we, the leadership of
                the Children’s Brain Tumor Network, have been humbled and
                gratified to work in service of CBTN’s mission for scientific
                discoveries that will benefit today’s children
              </p>
            </div>
          </Columns>

          <p>Gratefully,</p>
          <Image className="max-w-3xl" src={LeadershipSig} />
        </Content>
        <section
          className="relative bg-white my-16"
          aria-labelledby="join-heading"
        >
          <div
            className="hidden absolute inset-x-0 h-1/2 bg-blue-gray-50 lg:block"
            aria-hidden="true"
          ></div>
          <div className="max-w-7xl mx-auto bg-blue-600 lg:bg-transparent lg:px-8">
            <div className="lg:grid lg:grid-cols-12">
              <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
                <div
                  className="absolute inset-x-0 h-1/2 bg-blue-gray-50 lg:hidden"
                  aria-hidden="true"
                ></div>
                <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                  <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                    <Image
                      className="object-cover object-center rounded-3xl shadow-2xl"
                      src="https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images/Asset_3_WGT8IGW.png"
                    />
                  </div>
                </div>
              </div>

              <div className="relative bg-blue-600 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
                <div
                  className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                    width="404"
                    height="384"
                    fill="none"
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-blue-500"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="384"
                      fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                    />
                  </svg>
                  <svg
                    className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                    width="404"
                    height="384"
                    fill="none"
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-blue-500"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="384"
                      fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                    />
                  </svg>
                </div>
                <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                  <p className="text-lg text-white">
                    A new face on the leadership team this year is Dr. Brian
                    Rood of Children’s National Hospital, who became Co-Chair of
                    the CBTN Executive Board this summer.
                  </p>
                  <p className="text-lg text-white">
                    A CBTN investigator since 2015, Dr. Rood brings with him a
                    drive to support collaborative science, transparent data
                    sharing, and the translation of scientific discoveries into
                    new treatments for children and young adults suffering from
                    brain tumors around the world.
                  </p>

                  <p className="text-sm text-white">
                    Photo courtesy of Children’s National Hospital
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="mt-12" />
        <Content>
          <Columns>
            <div>
              <h2>From CBTTC to CBTN</h2>
              <h3>A NEW IDENTITY</h3>
              <p>
                The change in our organization’s identity from the Children’s
                Brain Tumor Tissue Consortium to the Children’s Brain Tumor
                Network marks a new phase in our efforts on behalf of childhood
                brain tumor patients everywhere.
              </p>
              <p>
                Now as the CBTN, we will further grow our partnerships and
                research efforts to cure pediatric brain tumors through
                innovation, resource sharing, a commitment to global inclusion,
                and most critically, the translation of data into new clinical
                trials and increased clinical impact. Through this expanded
                mission, we are determined to uncover more effective and less
                toxic therapies to benefit TODAY’s children.
              </p>
            </div>
            <Image
              height="310"
              className="self-center max-w-lg m-auto"
              src="https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images/Asset_12_2.png"
            />
          </Columns>
        </Content>
        <div className="mt-12" />
        <Content>
          <h2>Measuring Our Growth</h2>
          <h3>AN EXPANDED MISSION</h3>
          <Image
            className="max-w-lg lg:max-w-3xl m-auto"
            src="https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images/Asset_9_4uaFym9.png"
          />
          <p>
            In order to further accelerate research progress, CBTN is partnering
            with foundations, public agencies, commercial/pharmaceutical
            partners, and academic institutions to vastly expand the number of
            biospecimen and data research projects, as well as supporting
            increases of precision medicine trials while utilizing CBTN
            resources to inform trial development and deployment through our
            partners at PNOC.
          </p>
          <p>
            Despite the effect of COVID-19 on research across the world, the
            CBTN continued to expand. A total of 3,546 subjects have been
            enrolled (an increase of 202 this fiscal year), 45,686 aliquots of
            precious samples have been added to the biorepository, and
            highly-valuable, longitudinal clinical data, reports and imaging
            were collected to enhance the genomic data and samples available.
          </p>
          <p>
            CBTN not only grew in samples and data, but the network expanded in
            strategic partnerships with the addition of three new member
            institutions. Hassenfeld Children’s Hospital at NYU Langone (New
            York, New York) joined as a primary member site, and both Orlando
            Health Arnold Palmer Hospital for Children (Orlando, Florida) and
            Michigan Medicine C.S. Mott Children’s Hospital (Ann Arbor,
            Michigan) joined as satellite members. At the time of this report,
            CBTN has approved seven new member sites, all of which are currently
            onboarding.
          </p>
          <p>
            In another example of our drive to find cures for our children, CBTN
            celebrated a significant milestone during Brain Tumor Awareness
            Month in May. The 100th data project was approved by the CBTN
            Scientific Committee, exemplifying our commitment to open science
            and open access to our resources.
          </p>
        </Content>
      </div>
      <div id="patient-partnerships" className="mt-24">
        <Header className="bg-gradient-to-br from-brand-purple to-purple-900">
          <Curve />
          <HeaderContent>
            <HeaderTitle>Patient Partnerships</HeaderTitle>
            <HeaderImage src={PatientPartnershipsHeader} />
          </HeaderContent>
        </Header>
        <Content>
          <div className="flex justify-between items-center">
            <div>
              <h2>Foundation Partnerships</h2>
              <h3>A NEW FOUNDATION COUNCIL</h3>
            </div>
            <Image
              className="w-48"
              width="200"
              src="https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images/Asset_6_S0HlUsP.png"
            />
          </div>
          <Columns>
            <div>
              <p>
                During FY20, CBTN worked to better understand and support the
                needs of the patient community by hosting listening tours with
                each of CBTN’s
              </p>
              <p>
                Advisory Council member foundations. The Advisory Council,
                launched in 2017, was created with the goal of supporting CBTN’s
                vision and mission through advocacy, philanthropic and
                community-focused efforts.
              </p>
              <p>
                Thanks to feedback and insights collected during the foundation
                listening tours, CBTN restructured its foundation and donor
                partnerships into a new Executive Council.
              </p>
              <p>
                The new Executive Council will be charged with informing and
                partnering with CBTN leadership, acting as community stakeholder
                representatives, and driving the national and global impact of
                CBTN.
              </p>
            </div>
            <div>
              <p>
                Executive Council members provide a minimum annual gift of
                $25,000 and participate in quarterly meetings over a two-year
                membership commitment. Executive Council members also support
                CBTN through their service in one of two subcommittees, the
                Finance & Funding Subcommittee or the Advocacy & Awareness
                Subcommittee.
              </p>
              <p className="text-lg text-navy-50 bg-navy-800 p-4">
                If you are interested in learning more about the CBTN Executive
                Council, contact Gerri Trooskin, Director of Partnerships at{" "}
                <span className="text-navy-100 font-bold">
                  troosking@chop.edu
                </span>
              </p>
            </div>
          </Columns>
          <h3>ADVOCACY IN ACTION: DIPG AWARENESS DAY</h3>
          <Columns>
            <div>
              <p>
                On Thursday, February 13, 2020, at the Rayburn House Office
                Building in Washington, DC, diffuse intrinsic pontine glioma
                (DIPG) patient and family advocates joined national experts in
                childhood brain tumor research, as well as representatives from
                government and industry, at a Congressional Briefing titled
                DIPG, Pediatric Brain Cancer and the Importance of H. Res. 114.
                Presented by the DIPG Advocacy Group in collaboration with
                Congresswoman Jackie Speier (D-CA), the event was held to urge
                the 116th Congress to pass U.S. House Resolution 114 (H. Res.
                114).
              </p>
              <p>
                H. Res. 114 calls for the designation of the 17th of May each
                year as “DIPG Awareness Day” to raise awareness and encourage
                research into cures for diffuse midline gliomas (including
                DIPG), and for childhood cancers in general; as well as to open
                opportunities for data-sharing, and to push for increased
                private and public funding for research and new clinical trials
                for treatment.
              </p>
            </div>
            <div>
              <p>
                Additionally, the resolution would help to provide patient
                families and doctors with more information about available
                experimental research, clinical trials, and/or treatment
                strategies. beginning at the time of diagnosis.
              </p>
              <p>
                CBTN and PNOC investigators Dr. Adam Resnick and Dr. Sabine
                Mueller joined a panel of experts to deliver remarks on the
                state of research for these conditions. Jace Ward, a 21-year-old
                college student who was diagnosed with DIPG brain cancer in May
                of 2019, also shared remarks about his brain tumor journey.
                Since his diagnosis, Jace has become a leading patient voice,
                speaking to researchers, funders, public policymakers, and the
                community at-large to instill a sense of urgency in the
                discovery of new treatment strategies.
              </p>
            </div>
          </Columns>
        </Content>
        <div className="bg-white pt-16 lg:py-24">
          <div className="pb-16 bg-gradient-to-br from-brand-purple to-purple-900 lg:pb-0 lg:z-10 lg:relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
              <div className="relative lg:-my-8">
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
                />
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                  <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                    <Image
                      className="object-cover lg:h-full lg:w-full"
                      width="400"
                      src="https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images/Asset_11_oYByDfg.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                  <blockquote>
                    <div>
                      <svg
                        className="h-12 w-12 text-purple-300"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="mt-6 text-2xl font-medium text-white">
                        DIPG is not rare. It’s just rarely talked about. As a
                        nation, we are not aware. We are failing these patients
                        and their families. Awareness will lead to more cures.
                      </p>
                    </div>
                    <footer className="mt-6">
                      <p className="text-base font-medium text-white">
                        Jace Ward
                      </p>
                      <p className="text-base font-medium text-purple-100">
                        DIPG Patient & Research Advocate
                      </p>
                    </footer>

                    <p className="text-sm mt-6 text-purple-100">
                      DIPG patient and research advocates display signs and
                      photos of loved ones who passed away or were otherwise
                      affected by a childhood brain tumor during a meeting at
                      the Rayburn House Office Building in Washington D.C.
                      (Photo courtesy of CBTN)
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12" />
        <Content>
          <h2 className="text-center">A CALL TO ACTION</h2>
          <div className="md:flex md:items-center md:justify-center">
            <div className="md:flex-shrink-0">
              <Image
                className="mx-auto h-10 w-10 rounded-full"
                src={JaceWard}
                alt=""
              />
            </div>
            <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
              <div className="text-base font-medium text-gray-900">
                Jace Ward
              </div>

              <svg
                className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M11 0h3L9 20H6l5-20z" />
              </svg>

              <div className="text-base font-medium text-gray-500">
                DIPG Patient & Research Advocate
              </div>
            </div>
          </div>
          <Columns>
            <div>
              <p className="text-base bg-purple-100 text-purple-900 p-4">
                Jace Ward is a junior at Kansas State University studying
                business and pre-law. On May 17, 2019, following multiple rounds
                of tests and uncertainty, Jace was diagnosed with a diffuse
                intrinsic pontine glioma, or DIPG. Jace has become a strong
                advocate for the brain tumor patient community by bringing
                attention to these under-represented conditions. His mantra
                throughout his treatment has been, “I can’t die, I’m busy.”
              </p>
              <p>
                I have great hope and also frustration as I survey the landscape
                of data, research, and clinical trials. I feel the momentum
                rising in pockets like CBTN and yet I face the reality that my
                tumor won’t wait until we all agree on the most perfect ways to
                structure collaboration and trials.
              </p>
              <p>
                Since my diagnosis 18 months ago, I have watched a child die
                nearly every single day from DIPG alone; with parents sharing
                their heartbreaking progression. In the last decade, 1.3 million
                years of life have been lost to pediatric cancer - each one
                stolen away from children with unique talents and purpose in the
                world.
              </p>
              <p>
                What is to blame for the lack of progress in childhood brain
                tumor research? Is it lack of funding? Extreme caution in
                trials? Inability to effectively share and analyze data and
                biospecimens?
              </p>
            </div>
            <div>
              <p>
                Whatever the reason, I can guarantee every patient family knows,
                without a doubt, how much more important it is to efficiently
                share their child’s data, tumor tissue, and biospecimens than to
                keep those valuable resources locked away. Each one would opt
                for broadly sharing these resources for one main reason: No
                family who has experienced the pain of childhood cancer would
                wish it on any other.
              </p>
              <p>
                I have great hope because I have had some success in treatment
                when I have used my data through CBTN for analysis by trial
                investigators, pharmaceutical companies and doctors.
              </p>
              <p>
                Still, far too few discoveries have been made in past decades
                and now we must play catch up. No one can better teach us
                bravery in action than a child with brain cancer. Let’s start
                right now by really seeing the child, each child, as if he or
                she is our own. Let’s work urgently to save them.
              </p>
              <p>
                In the words of the late Congressman John Lewis, “If not us,
                then who? If not now, then when?”
              </p>
              <p>
                Friends, we each get one life to make an impact. My case is not
                any different than yours. None of us know how many days we have
                on earth to do our best work. Use every single one. - Jace
              </p>
            </div>
          </Columns>
        </Content>
        <section className="py-12 overflow-hidden md:py-20 lg:py-24">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative">
              <blockquote className="mt-10">
                <div className="max-w-5xl mx-auto text-center text-2xl leading-9 font-medium text-purple-900">
                  <p>
                    &ldquo;DIPG won’t wait. We know it is possible to use
                    urgency to chart a new course; we have seen this during the
                    COVID-19 crisis. Urgency requires a united commitment to
                    somewhat rebellious collaboration. It requires deciding a
                    patient deserves the right to life and the pursuit of
                    lifeextending treatments. It will require bravery to change
                    institutional thinking.&rdquo;
                  </p>
                </div>
              </blockquote>
            </div>
          </div>
        </section>
      </div>
      <div id="scientific-partnerships" className="mt-24">
        <Header className="bg-gradient-to-br from-brand-blue to-blue-600">
          <Curve />
          <HeaderContent>
            <HeaderTitle>Scientific Community Partnerships</HeaderTitle>
            <HeaderImage
              src={ScientificPartnershipsHeader}
              caption="Members of the Centre for Cancer Research at the Hudson Institute of Medical Research, including Ron Firestein, MD, PhD and Jason Cain, PhD of the CBTN
              Scientific Committee (Photo courtesy of Hudson Institute of Medical Research)."
            />
          </HeaderContent>
        </Header>
        <Content>
          <h2>Driving Discovery through Collaboration</h2>
          <h3>PNOC 008</h3>

          <Columns>
            <div>
              <p>
                CBTN continued its collaborative efforts with the Pacific
                Pediatric Neuro-Oncology Consortium (PNOC) through the PNOC008
                trial titled, ‘A Pilot Trial Testing the Clinical Benefit of
                Using Molecular Profiling to Determine an Individualized
                Treatment Plan in Children and Young Adults with High-grade
                Glioma’.
              </p>
              <p>
                The first subject was enrolled in April of 2019. With a target
                of 1-2 subjects per month across any PNOC site that is IRB
                approved for the study, tumor samples are collected for clinical
                genomics, which are generated in the CLIA-certified lab at UCSF
                followed by the production of a clinical report.
              </p>
            </div>
            <div>
              <p>
                If additional tissue is available, the sample is sent for
                research sequencing first via the Division of Genomic
                Diagnostics at CHOP for extractions and then to a third-party
                vendor to generate the data.
              </p>
              <p>
                Once the data is received, analysis is run against the Pediatric
                Brain Tumor Atlas (PBTA) with another clinical report being
                produced. Both reports are provided to the PNOC Tumor Board for
                review and to aid in treatment decisions for the subject. The
                data then is incorporated back into the PBTA to inform on
                treatment decisions for future subjects. In 2020, 19 patients
                were enrolled, nine of which had sequencing completed through
                the division of Genomic Diagnostics at CHOP.
              </p>
            </div>
          </Columns>
          <h3>CBTN/PNOC WORKING GROUPS</h3>
          <p>
            This year, CBTN launched a new collaborative effort with our
            partners at the Pacific Pediatric Neuro-Oncology Consortium (PNOC),
            supporting our increased focus on clinical trial and clinical
            decision support. Seven CBTN/ PNOC Disease Working Groups have been
            formed to focus research efforts for a number of histologies and
            research areas.
          </p>
          <p>
            Working groups have been established for: medulloblastoma, diffuse
            midline glioma, AT/RT, low-grade glioma, ependymoma,
            craniopharyngioma, and imaging. Each working group has their own
            goals, convene once every month, and collaborate on preclinical and
            clinical research. The groups are facilitated with CBTN by allowing
            for sharing of resources, clinical data, biospecimens, preclinical
            models and genomic and proteomic data that can be used to develop
            new data-driven clinical trials.
          </p>
          <p>
            If you would like to participate or learn more about the CBTN/PNOC
            working groups, please email Ryan Velasco, Research Program
            Coordinator, (velascor@chop.edu).
          </p>
        </Content>
        <div className="mt-12" />
        <Content>
          <h3>CBTN/PNOC WORKING GROUP LEADS</h3>
          <Columns>
            <div>
              <h4>MEDULLOBLASTOMA LEADS</h4>
              <ul>
                <li>Robert Wechsler-Reya, PhD; Sanford Burnham Prebys</li>
                <li>Tabitha Cooney, MD; UCSF Benioff Children’s Hospital</li>
              </ul>
              <h4>CRANIOPHARYNGIOMA LEADS</h4>
              <ul>
                <li>Cassie Kline, MD; Children’s Hospital of Philadelphia</li>
                <li>Fatema Malbari, MD; Texas Children’s Hospital</li>
              </ul>
              <h4>LOW GRADE GLIOMA LEADS</h4>
              <ul>
                <li>
                  Angela Waanders, MD, MPH; Ann & Robert H. Lurie Children’s
                  Hospital of Chicago
                </li>
                <li>
                  Daphne Adele Haas-Kogan, MD; Dana-Farber Cancer Institute
                </li>
                <li>
                  Pratiti Bandopadhayay, MBBS, PhD; Dana-Farber Cancer Institute
                </li>
                <li>
                  Joanna Phillips, MD, PhD; UCSF Helen Diller Family
                  Comprehensive Cancer Center
                </li>
              </ul>
              <h4>AT/RT LEADS</h4>
              <ul>
                <li>Ashley Margol, MD, MS; Children’s Hospital Los Angeles</li>
                <li>Annie Huang, MD, PhD, FRCP(C); SickKids</li>
                <li>Eric Raabe, MD, PhD; Johns Hopkins School of Medicine</li>
              </ul>
            </div>
            <div>
              <h4>EPENDYMOMA LEADS</h4>
              <ul>
                <li>Eugene Hwang, MD; Children’s National Hospital</li>
                <li>Mariella Filbin, MD, PhD; Dana-Farber Cancer Institute</li>
                <li>Steven Mack, PhD; Texas Children’s Hospital</li>
                <li>Derek Hanson, MD; Hackensack Meridian Health</li>
              </ul>
              <h4>DIFFUSE MIDLINE GLIOMA LEADS</h4>
              <ul>
                <li>Sabine Mueller, MD, PhD; Children’s Hospital Zurich</li>
                <li>Javad Nazarian, PhD; Children’s Hospital Zurich</li>
              </ul>
              <h4>IMAGING WORKING GROUP LEADS</h4>
              <ul>
                <li>
                  Josh Rubin, MD, PhD; Washington University School of Medicine,
                  St. Louis
                </li>
                <li>
                  Javier Villaneuva-Meyer, MD; University of California San
                  Francisco
                </li>
                <li>S. Ali Nabavizadeh, MD; University of Pennsylvania</li>
              </ul>
            </div>
          </Columns>
        </Content>
        <Content>
          <Columns>
            <div>
              <h3>OPEN PBTA</h3>
              <p>
                Developed by the CBTN in collaboration with the Pacific
                Pediatric Neuro-Oncology Consortium (PNOC), the Pediatric Brain
                Tumor Atlas (PBTA) is a global effort to accelerate discoveries
                for therapeutic intervention for children diagnosed with brain
                tumors through the non-embargoed, pre-publication release of one
                of the largest genomic datasets for pediatric brain tumors to
                date. This foundational dataset was released on the NIH Common
                Fund-supported Gabriella Miller Kids First Data Resource Portal
                and CAVATICA workspace.
              </p>
              <p>
                In FY20, CBTN researchers at Children’s Hospital of Philadelphia
                (CHOP) and the Childhood Cancer Data Lab (CCDL) at Alex’s
                Lemonade Stand Foundation set out to launch the OpenPBTA
                Project, a first-in-kind collaborative, crowd-sourcing analytic
                global initiative. This new open science project seeks to
                harness the collective expertise of the biomedical research and
                data informatics community to comprehensively describe the PBTA
                and accelerate scientific discoveries that will lead to clinical
                impact for childhood cancer patents.
              </p>
              <p>
                The OpenPBTA Project is organized on GitHub, which allows data
                scientists to work together. Bioinformaticians and data
                scientists anywhere on earth are invited to join the project at
                the OpenPBTA GitHub Analysis Repository. This crowd-sourced,
                open-analysis effort is contributing to a manuscript that will
                provide a detailed, molecular picture of the more than 30
                pediatric brain tumor types represented within the PBTA. The
                completed manuscript will help laboratory scientists and
                clinicians to more clearly identify, describe, and chart how
                cancerous brain cells mutate and propagate separate from normal
                tissue cells. The OpenPBTA manuscript will continue to evolve
                and expand, with updates available in real-time and on an
                on-going basis. This will allow for continuous team science
                efforts and provide an expanding resource to empower additional
                scientific discoveries. To learn more about the Open PBTA
                initiative, visit cbtn.org or ccdatalab.org/openpbta.
              </p>
            </div>
            <div>
              <h3>GIFT FROM A CHILD</h3>
              <p>
                Gift from a Child (GFAC), supported by the Swifty Foundation,
                ensures patients and families can donate samples of brain tumor
                tissue at the end of life. The gift of postmortem tissue
                donation benefits other children diagnosed with a pediatric
                brain tumor and can provide families with hope and consolation
                during a time of loss.
              </p>
              <p>
                GFAC Centers of Excellence, including the newest site at Weill
                Cornell Medicine, work to collect, process and track donations
                to the CBTN. This initiative allows each donated sample to
                provide the greatest possible impact on children’s brain tumor
                research. The collection of these samples is key to further
                understand the biological and molecular changes that occur
                within brain tumors, especially in tumors that were not
                surgically possible to biopsy or resect due to location. In
                addition to tumor tissue, this effort allows the collection of
                normal brain tissue samples to use for comparison.
              </p>
              <p>
                To learn more about Gift from the Child initiative, visit
                cbtn.org or giftfromachild.org.
              </p>
              <h3>NF2 BIOSOLUTIONS</h3>
              <p>
                To support expanded cross-disease research, CBTN partnered with
                NF2 Biosolutions, a nonprofit organization dedicated to finding
                an effective treatment or cure for Neurofibromatosis 2 (NF2).
                NF2 is a disease characterized by tumors on the spinal and
                cranial nerves, with over fifty percent of the cases inherited.
                The afflicted often suffer hearing and vision loss.
              </p>
              <p>
                By leveraging the CBTN’s infrastructure and resources, NF2
                Biosolutions will refer patients to CBTN Clinical Research
                Coordinators, who will consent and coordinate the collection of
                samples and data from previous and upcoming surgeries. Any data
                generated will be available for research and also allow
                cross-disease analysis to expand potential findings.
              </p>
              <p>To learn more, visit nf2biosolutions.org</p>
            </div>
          </Columns>
        </Content>
        <div className="bg-white pt-16 lg:py-24">
          <div className="pb-16 bg-gradient-to-br from-brand-blue to-blue-600 lg:pb-0 lg:z-10 lg:relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 ">
              <div className="relative lg:-my-8">
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
                />
              </div>
              <div className="mt-12 lg:m-0 lg:pl-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                  <blockquote>
                    <div>
                      <svg
                        className="h-12 w-12 text-blue-300"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="mt-6 text-2xl font-medium text-white">
                        The expansion of CAVATICA has already enhanced
                        international collaboration by providing easier and
                        faster approaches to sharing data and analyzing rare
                        childhood cancer data.
                      </p>
                      <p className="mt-6 text-2xl font-medium text-white">
                        Since we know so little about many pediatric cancer
                        subtypes, it is essential that we share data globally.
                        We may be able to better understand how to treat an
                        Australian patient’s tumour by comparing it to larger
                        numbers of American patients.
                      </p>
                      <p className="mt-6 text-2xl font-medium text-white">
                        The innovative technology of CAVATICA enables
                        researchers from Australia and the United States to
                        seamlessly share data and novel analysis methods with
                        ease, driving improved outcomes and novel research.
                      </p>
                    </div>
                    <footer className="mt-6">
                      <div className="md:flex md:items-center md:justify-center">
                        <div className="md:flex-shrink-0">
                          <Image
                            className="mx-auto h-10 w-10 rounded-full"
                            src={MarkCowley}
                            alt=""
                          />
                        </div>
                        <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                          <div className="text-base font-medium text-white">
                            Mark Cowley, PhD
                          </div>

                          <svg
                            className="hidden md:block mx-1 h-5 w-5 text-blue-300"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M11 0h3L9 20H6l5-20z" />
                          </svg>

                          <div className="text-base font-medium text-blue-100">
                            Associate Professor & Computational Group Leader
                          </div>

                          <svg
                            className="hidden md:block mx-1 h-5 w-5 text-blue-300"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M11 0h3L9 20H6l5-20z" />
                          </svg>

                          <div className="text-base font-medium text-blue-100">
                            Children’s Cancer Institute
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Content>
          <Columns>
            <div>
              <h3>CAVATICA AUSTRALIA</h3>
              <p>
                Beginning in February of 2020, The Children’s Brain Tumor
                Network joined an international alliance including the
                Australian Research Data Commons (ARDC), Bioplatforms Australia,
                the Australian BioCommons, Children’s Cancer Institute in
                Australia, The Gabriella Miller Kids First Data Resource Center
                (Kids First DRC), and Seven Bridges Genomics to expand use of
                the CAVATICA data analysis platform into Australia, utilizing
                Amazon Web Services in Sydney and piloting new capabilities to
                support Australian initiatives such as Zero Childhood Cancer
                (Zero).
              </p>
              <p>
                These CBTN partners aim to build a computational infrastructure
                that will harmonize data from Zero, with the genomic datasets of
                CBTN and the Kids First DRC. The effort will help to improve
                researchers’ understanding of rare pediatric brain cancer
                subtypes.
              </p>
              <p>
                In late 2018, the Kids First DRC launched an open-source
                research portal for pediatric cancers and structural birth
                defects which includes DNA and RNA samples from CBTN. The Kids
                First DRC, which is supported through the National Institutes of
                Health (NIH) Common Fund’s Gabriella Miller Kids First Pediatric
                Research Program, also uses the CAVATICA platform for storing,
                sharing, and analyzing large volumes of pediatric genomic data.
              </p>
            </div>
            <div>
              <h3>OLIGONATION</h3>
              <p>
                Oligodendroglioma is rare, occurring in less than one percent of
                pediatric brain tumor patients under 14 years of age, although
                slightly more often in teens and young adults. These and other
                factors present challenges to identify clinical trials or
                promising research advancements for young people who develop an
                Oligodendroglioma. And because Oligodendroglioma tends to be a
                slow growing cancer, the lack of short term ‘endpoints’ for
                Oligo trials makes it more difficult to recruit support from
                researchers, drug companies, and government agencies such as the
                Federal Drug Administration (FDA).
              </p>
              <p>
                Without sufficient data to develop hypotheses or the preclinical
                tools to test them, progress is difficult. To address these
                needs, OligoNation was founded in 2014 and has since raised over
                $1.3 million for research. With an initial emphasis on
                translational research, OligoNation is now focusing efforts on
                biobanking and facilitating preclinical research (cell-line and
                PDX model development) that will lead to more clinical trials
                for Oligodendroglioma.
              </p>
              <p>
                As CBTN and OligoNation align on many of the same goals, a new
                partnership was formed during FY20 to establish a pipeline for
                gathering Oligodendroglioma tissue samples and providing data to
                the research community. A collated sample listing from across
                CBTN, the University of Pennsylvania and OligoNation have
                undergone whole-genome and RNA sequencing. Once analysis of the
                122 DNA and 62 RNA Oligo cohorts is completed, the findings will
                be made available to benefit the research and patient community.
              </p>
            </div>
          </Columns>
        </Content>
        <div className="mt-12" />
        <Content>
          <h2>Scientific Community Resources</h2>
          <Columns>
            <div>
              <h3>USER SUPPORT OFFICE HOURS</h3>
              <p>
                To provide technical support and resources for the CBTN research
                community, ‘user support office hours’ were instituted during
                FY20. These open information sessions allow researchers to ask
                questions or view tutorials from experts on the CBTN Operations
                Team. Topics range from how to navigate CBTN’s analysis
                platforms, to querying data, to discovering or creating
                pipelines and workflows
              </p>
              <p>
                Researchers and clinicians seeking to learn more about the
                CBTN’s available pediatric brain tumor data are invited to join
                each monthly session. During these sessions, tutorials and
                support topics for using platforms including CAVATICA,
                PedcBioPortal and the Gabriella Miller Kids First Data Research
                Portal are available upon request.
              </p>
              <div className="text-base bg-blue-100 text-blue-900 px-4 py-1">
                <p>INTERESTED IN JOINING OFFICE HOURS?</p>
                <p>
                  Contact a member of the CBTN operations team for meeting
                  information, to submit questions, or suggest discussion
                  topics.
                </p>
                <p>Email us at reseach@cbtn.org</p>
              </div>
            </div>
            <div>
              <h3>EXPANDED RESOURCE REQUESTS</h3>
              <p>
                After receiving feedback from researchers about the CBTN’s
                specimen request process, CBTN has phased out its quarterly
                request periods, allowing researchers to submit specimen
                requests at any time. This update was made possible through the
                formation of two review groups within the CBTN Scientific
                Committee. Members of the CBTN Scientific Committee review and
                provide feedback and/or approval for all submitted project
                requests. The CBTN also accepts requests for cell lines and data
                projects year-round, which are available to researchers anywhere
                on earth.
              </p>

              <div className="text-base bg-blue-100 text-blue-900 px-4 py-1">
                <p>NEED TO REQUEST SPECIMENS OR DATA?</p>
                <p>
                  For information and instructions on submitting a request to
                  CBTN biosamples, cell lines and data, visit
                  cbtn.org/research-resources
                </p>
              </div>
            </div>
          </Columns>
        </Content>
      </div>
      <div id="advancing-mission" className="mt-24">
        <Header className="bg-gradient-to-br from-brand-orange to-orange-600">
          <Curve />
          <HeaderContent>
            <HeaderTitle>Advancing Our Mission</HeaderTitle>
            <HeaderImage
              width="1200"
              src="https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images/Asset_10_mcySca6.png"
              caption="Chanel Keoni, BS, a Neurosurgery Lab Coordinator at Dayton Children’s Hospital. (Photo courtesy of Dayton Children’s Hospital)"
            />
          </HeaderContent>
        </Header>
        <Content>
          <h2>CBTN Executive Board FY21 Objectives</h2>

          <Columns>
            <div>
              <h3>DRIVING INNOVATIVE, PIONEERING, OPEN SCIENCE</h3>
              <h4>Objective 1</h4>
              <p>
                Strategically growing the number of CBTN members in the U.S. and
                internationally will drive the availability, utilization and
                increased volume of pediatric brain tumor resources, research,
                and increase the network of collaborative innovative experts
                overcoming the inter-related barriers to advancing pediatric
                brain tumor research.
              </p>
              <h4>Objective 2</h4>
              <p>
                Increase the number of U.S. and international research projects,
                and discoveries supported by CBTN aligned to the open science
                model for release without embargo of data, biospecimens and
                preclinical models throughout the research community.
              </p>
              <h4>Objective 3</h4>
              <p>
                Increase the number of U.S. and international precision medicine
                clinical trials and therapeutic treatment decision mechanisms
                supported by CBTN’s pediatric and AYA data to allow clinical
                trials to be designed and supported by pediatric and AYA brain
                tumor data.
              </p>
              <h4>Objective 4</h4>
              <p>
                Improve equity of global access to healthcare and to clinical
                trials by developing CBTN led research looking at health
                disparities in pediatric brain tumor patients.
              </p>

              <h4>Objective 5</h4>
              <p>
                Increase and drive patient and foundation collaborations to
                develop partnered research projects, clinical trials,
                collaborative opportunities aligned with CBTN’s vision and
                mission to improve treatments, outcomes and quality of life.
              </p>
            </div>
            <div>
              <h3>
                DEVELOPING AND SUSTAINING ESSENTIAL STRATEGIC PARTNERSHIPS
              </h3>
              <h4>Objective 1</h4>
              <p>
                Increase and drive governmental pediatric brain tumor research
                in the U.S. and internationally for increased resources,
                collaboration and expertise in pediatric brain and AYA brain
                tumor.
              </p>
              <h4>Objective 2</h4>
              <p>
                Increase and drive commercial partnerships to drive the
                utilization of first class innovative solutions and technology
                for pediatric brain tumor research.
              </p>
              <h4>Objective 3</h4>
              <p>
                Establish pharmaceutical partnerships for the development of
                pediatric brain tumor clinical trials and therapies supported by
                CBTN.
              </p>

              <h3>DRIVING INNOVATIVE, PIONEERING, OPEN SCIENCE</h3>
              <h4>Objective 1</h4>
              <p>
                Strategically growing the number of CBTN members in the U.S. and
                internationally will drive the availability, utilization and
                increased volume of pediatric brain tumor resources, research,
                and increase the network of collaborative innovative experts
                overcoming the inter-related barriers to advancing pediatric
                brain tumor research.
              </p>
            </div>
          </Columns>
        </Content>
        <div className="mt-12" />
        <Content>
          <h2>Moving Our Mission Forward</h2>
          <Columns>
            <div>
              <h3>NEW CBTN WEBSITE</h3>
              <p>
                Growth from CBTTC to CBTN has continued into FY21 with the
                launch of our redesigned website (cbtn.org) in October 2020.
                With a greater focus on the CBTN’s scientific discovery
                projects, the new CBTN website provides a opportunities for
                visitors to explore, learn, and connect with CBTN investigators,
                foundations, and research partners.
              </p>
              <p>
                CBTN website visitors can explore the biospecimen and data
                project pages while learning more about the research teams,
                institutions, and foundations involved with each project. Users
                can also view project goals, funding needs and each project’s
                impact to the CBTN. The redesigned member institution pages
                allow visitors to learn more about the investigators,
                clinicians, research coordinators, and other CBTN research team
                members at each CBTN member institution. Each section of the
                website is linked to other relavant informational cards
                including publications, presentations and news items.
              </p>
              <p>
                Additional website features highlight CBTN’s research findings,
                including a ‘Publications’ section, an ‘About Us’ section which
                shares how CBTN empowers discovery through data-driven research,
                foundation partner pages which highlight the critical role of
                the patient communities in the CBTN mission, a newly-designed
                donation platform, and much more.
              </p>
            </div>
            <div>
              <h3>CBTN/CPTAC PROTEOMICS PAPER</h3>
              <p>
                CBTN and the Clinical Proteomic Tumor Analysis Consortium
                (CPTAC) collected and analyzed genetic, genomic, and proteomic
                data from multiple types of brain tumors in children. The
                research team consists of collaborators from the Icahn School of
                Medicine at Mount Sinai, the National Cancer Institute, Fred
                Hutchinson Cancer Research Center, Children’s National Hospital
                and Children’s Hospital of Philadelphia.
              </p>
              <p>
                This paper is the first large-scale multicenter study focused on
                pediatric brain tumors, available online in Cell on Nov. 25,
                2020. A comprehensive “proteogenomic” analysis of the proteins,
                genes, and RNA transcription involved in pediatric brain tumors
                has yielded a more complete understanding of these tumors, which
                are the leading cause of cancer-related death in children. The
                results could help physicians more accurately identify different
                types of tumors and methods for treating them.
              </p>
              <p>
                This study is the first comprehensive survey of genomics (aims
                to characterize DNA sequence alterations in samples),
                transcriptomics (aims to quantify copies of RNAs), global
                proteomics (aims to identify and quantify proteins), and
                phosphoproteomics (quantifies active proteins) across a large
                cohort of 218 tumor samples representing seven distinct types of
                brain tumors.
              </p>
              <p>
                By characterizing biological themes that are shared among these
                different types of tumors, the study revealed new insights which
                suggest that current treatments being used for specific tumor
                types could be applied to others that share the same proteomic
                features. Through leveraging the rich clinical outcome data of
                this cohort, the research team also identified new prognostic
                biomarkers for a type of tumor known as high-grade glioma (HGG).
                When HGG tumors have a genetic mutation known as a H3K27M
                mutation, they tend to be very aggressive and the patients have
                relatively short survival time. However, in those without the
                mutation, this study suggests that the abundance of proteins
                named IDH1 and IDH2 in the tumor tissues could help to identify
                which tumors with the non-mutated H3K27M gene may be less
                aggressive.
              </p>
              <p>
                The data analysis also showed key biological differences in
                samples from primary and recurrent tumors from the same
                patients, indicating the need for independent assessment and
                therapeutic decisions for these tumors.
              </p>
            </div>
          </Columns>
        </Content>

        <div className="mt-12" />
        <Content>
          <h2>Financial Summary</h2>
          <h3>FY19-20 CBTN FINANCIAL SUMMARY</h3>
          <Columns>
            <p>
              The CBTN is truly grateful to have been supported by Children’s
              Hospital of Philadelphia, including the Division of Neurosurgery
              and the Center for Data Driven Discovery in Biomedicine (D3b), as
              well as the generosity of individuals, families and foundations
              since its inception. These philanthropic efforts highlight the
              network of partners who are dedicated to the CBTN’s mission and
              vision.
            </p>
            <div>
              <p className="text-base bg-orange-100 text-orange-900 px-4 py-1 inline-block">
                Note: Other resources provided by Children’s Hospital of
                Philadelphia, not reflected in these numbers, include use of the
                Hospital’s state-of-the-art biorepository, bioinformatics
                platforms developed through other grants and mechanisms,
                additional staff support from the more than 60 members of the
                D³b Center, and gift processing and donor relations support from
                CHOP Foundation.
              </p>
            </div>
          </Columns>
          <Image className="m-auto max-w-lg lg:max-w-3xl" src={Budget} />
        </Content>
      </div>
    </main>
    <div className="mt-24" />
    <Footer />
  </>
);

export default AnnualReport;
